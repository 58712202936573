import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Obfuscate from 'react-obfuscate';
import Layout from '../components/Layout';
import Content from '../components/Content';

const ImageStyles = styled.div`
  display: block;
  max-width: 880px;
  margin: 0 auto var(--pad);

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const AboutStyles = styled.div`
  max-width: 640px;
  margin: 0 auto;

  @media (hover: hover) and (pointer: fine) {
    a:hover {
      color: var(--red);
    }
  }
`;

export default function Home({ data: { about } }) {
  return (
    <Layout page="Creative copywriter based in London">
      <Content>
        <ImageStyles>
          <GatsbyImage
            className="home-image"
            image={getImage(about.about_photo.asset)}
            alt="Kate Langham"
            width={900}
            aspectRatio={16 / 9}
            layout="fixed"
          />
        </ImageStyles>
        <AboutStyles>
          <p>{about.about_text}</p>
          <div style={{ marginTop: '1em' }}>
            <Obfuscate email="kate.j.langham@gmail.com">Say hi.</Obfuscate>
          </div>
        </AboutStyles>
      </Content>
    </Layout>
  );
}

export const query = graphql`
  query {
    about: sanityAbout {
      about_text
      about_photo {
        asset {
          gatsbyImageData(width: 1280)
        }
      }
    }
  }
`;
